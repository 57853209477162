<template>
  <block
    component-name="block-text-btn-list"
    body-class="grid grid-cols-12 gap-x-ui gap-y-3xl"
  >
    <txt-chapter
      size="big"
      :overline="$attrs.data.body.overline || undefined"
      :title="$attrs.data.body.title"
      :text="$attrs.data.body.text || undefined"
      :button="$attrs.data.body.button || undefined"
      class="col-span-full laptop:col-start-1 laptop:col-end-7 w-full"
    />

    <btn-list
      v-if="$attrs.data.body.list?.items?.length"
      :asset="$attrs.data.body.list.asset || undefined"
      :title="$attrs.data.body.list.title || undefined"
      :items="$attrs.data.body.list.items"
      class="col-span-full laptop:col-start-8 laptop:col-end-12 w-full"
    />
  </block>
</template>
